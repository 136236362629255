<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="10"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="9">
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              title=" ! CONVOCATORIA DE CERTIFICACIÓN 2025 !"
            />

            <base-body space="0">
              <span class="white--text">
                Ya se encuentra abierta la Convocatoria de Certificación para el ciclo 2025.
              </span>
              <p class="white--text">
                Consulta las bases en el siguiente link
              </p>
            </base-body>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <base-btn
              :block="$vuetify.breakpoint.smAndDown"
              color="white"
              :href="path_convocatoria_certificacion"
              large
              outlined
              target="_blank"
            >
              CONVOCATORIA CERTIFICACIÓN 2025
            </base-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>


<script>
  export default {
    name: 'ConvocatoriaCertificacion',
    data: () => ({
      path_convocatoria_certificacion:process.env.VUE_APP_BASE_URL+'/convocatorias/2025/convocatoria_de_certificacion_2025.pdf',
      //path_convocatoria_recertificacion:process.env.VUE_APP_BASE_URL+'/convocatorias/2023/convocatoria_recertificacion_2023B.pdf',
    }),
    methods:{

    }
  }
</script>
